/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    h2: "h2",
    ul: "ul",
    li: "li",
    img: "img",
    em: "em"
  }, _provideComponents(), props.components), {HrefLangManager, VideoIframe, ButtonCta} = _components;
  if (!ButtonCta) _missingMdxReference("ButtonCta", true);
  if (!HrefLangManager) _missingMdxReference("HrefLangManager", true);
  if (!VideoIframe) _missingMdxReference("VideoIframe", true);
  return React.createElement(React.Fragment, null, React.createElement(HrefLangManager, {
    alternates: [['x-default', 'https://www.hear.com/resources/hearing-aids/4-reasons-why-more-people-are-wearing-hearing-aids/'], ['en', 'https://www.hear.com/resources/hearing-aids/4-reasons-why-more-people-are-wearing-hearing-aids/'], ['en-US', 'https://www.hear.com/resources/hearing-aids/4-reasons-why-more-people-are-wearing-hearing-aids/'], ['en-CA', 'https://ca.hear.com/resources/hearing-loss/4-reasons-why-more-people-are-wearing-hearing-aids/']]
  }), "\n", React.createElement(_components.h1, {
    className: "c-md-h1",
    id: "4-reasons-why-more-people-are-wearing-hearing-aids",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#4-reasons-why-more-people-are-wearing-hearing-aids",
    "aria-label": "4 reasons why more people are wearing hearing aids permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "4 Reasons Why More People Are Wearing Hearing Aids"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Every day over thousands of Canadians get new hearing aids and that number is growing. Here are the top 4 reasons why."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "1-hearing-aid-technology-has-improved-significantly",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#1-hearing-aid-technology-has-improved-significantly",
    "aria-label": "1 hearing aid technology has improved significantly permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "1. Hearing aid technology has improved significantly"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Similar to smartphones, hearing aid technology is advancing so fast it’s hard to keep up. Smaller, faster processors combined with sophisticated algorithms have improved the effectiveness of hearing aids to create a natural, effortless listening experience. Some hearing aids have been clinically proven to deliver better than normal hearing in certain situations."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "With Bluetooth connectivity, you can easily stream music, podcasts or phone calls directly to your hearing aids. Many are also rechargeable and include on-the-go chargers similar to Apple’s AirPod case, so there’s no more fumbling around with tiny batteries."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "To further prove the point, hearing aids have recently won notable awards at some of the biggest tech & design events…"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Phonak’s Virto Black won Engadget’s Best of CES"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Starkey’s Livio made Time Inc’s List of the Best Inventions"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Signia’s Styletto won a Red Dot Design Award"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Oticon’s Xceed won a CES Innovation Award"), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.img, {
    src: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/dims.jpg",
    alt: "CES winner img, hearing aids",
    className: "c-md-img"
  })), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "2-people-are-getting-hearing-aids-earlier",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#2-people-are-getting-hearing-aids-earlier",
    "aria-label": "2 people are getting hearing aids earlier permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "2. People are getting hearing aids earlier"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Contrary to popular belief, the National Institutes of Health reports that hearing loss begins by age 39 in 49% of people and 81% by the age of 59. We live in a noisy world that exposes our ears to heavy traffic, loud restaurants, rock concerts, and sporting events. In addition, listening to music with headphones and earbuds are proven to further harm our hearing. With advanced hearing technology and smartphone connectivity, Gen-Xers see the benefits of hearing aids and are acting much quicker than older generations. Many of them already wear Bluetooth devices in their ears, so wearing a hearing aid is no big deal."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.img, {
    src: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/hearing-loss-chart.png",
    alt: "hearing loss chart",
    className: "c-md-img"
  })), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "3-hearing-aids-improve-work-performance",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#3-hearing-aids-improve-work-performance",
    "aria-label": "3 hearing aids improve work performance permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "3. Hearing aids improve work performance"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Since 2009, EuroTrak has surveyed over 175,000 people with hearing loss and hearing aids. Not surprisingly, 91% report improvement at work, but it’s impressive to see wearers reporting $44,000 of higher earnings throughout their remaining working years! Good communication is critical to career success and medical-grade hearing aids are programmed to improve speech understanding. They make it easier to understand what someone is saying, so your brain can put more energy towards critical thinking, communication, creativity, and collaboration."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.img, {
    src: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/hearing-aids-work-performance.png",
    alt: "hearing loss chart",
    className: "c-md-img"
  })), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "4-research-shows-untreated-hearing-loss-hurts-our-health",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#4-research-shows-untreated-hearing-loss-hurts-our-health",
    "aria-label": "4 research shows untreated hearing loss hurts our health permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "4. Research shows untreated hearing loss hurts our health"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Our bodies, brains, and overall health are more connected than many of us think. Here is just one example of how hearing loss affects more than our ability to understand."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Doctors at Johns Hopkins Medicine have conducted multiple studies linking hearing loss with increased risks for depression, social isolation, cognitive decline, dementia and even falling. They’ve also proven that treating hearing loss with hearing aids can reduce these risks."), "\n", React.createElement(VideoIframe, {
    title: "Better Hearing and Speech Month | Johns Hopkins Cochlear Implant Center",
    width: "560",
    height: "315",
    src: "https://www.youtube.com/embed/lWFZxsOWOhE"
  }), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.em, null, "Dr. Frank Lin discusses how 1 in 5 Americans have hearing loss, and how it can impact your everyday life.")), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "experience-the-difference-yourself",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#experience-the-difference-yourself",
    "aria-label": "experience the difference yourself permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Experience the difference yourself"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "You can read study after study and the findings will always be the same: hearing aids help people live better and healthier lives. But the only way to experience the benefits of these findings is to try them for yourself. This is why hear.com offers a 30-day trial of the best hearing aids from the leading manufacturers. If you haven’t scheduled your appointment yet, make sure to click the button below."), "\n", React.createElement(ButtonCta, {
    copy: "Sign up for a 30-day trial",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
